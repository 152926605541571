@import url(https://fonts.googleapis.com/css2?family=League+Spartan:wght@100;200;300;400;500;600;700;800;900&display=swap);
* {
  margin: 0;
  padding: 0;
  font-family: "League Spartan", sans-serif;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  width: 100%;
  min-height: calc(100vh - 5vh);
  /* padding-bottom: 20px; */
}
body {
  overflow-x: hidden;
}
  
.footer {
  /* position: absolute; */
  /* bottom: 0; */
  width: 100%;
  background-color: #827fb3;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  overflow-x: hidden;
  box-sizing: border-box;

}
.footer a{
  color: #080434;
  text-decoration: none;
  transition: color 200ms ease-in-out;
}
.footer p{
  color: rgb(198, 198, 198);
  font-size: large;
}
.footer p .marginSpan{
  color: rgb(162, 162, 162);
  margin: 0 10px;
  font-size: large;

}
.footer p .colorSpan{
  color: #080434;
}
.App .header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.App .header .hbg {
  position: absolute;
  top: 0;
  width: 100%;
  height: 50%;
  background-color: #424a9f;
  /* background-color: #838383; */
  z-index: -1;
}

.App .header img {
  width: 120px;
  padding: 40px 80px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.App .content {
  position: relative;
}

.App .content .buttons {
  position: absolute;
  top: 0;
  right: 100px;
  display: flex;
  grid-gap: 20px;
  gap: 20px;
}

.App .content .buttons button,
.App .content .buttons .button2 {
  padding: 15px;
  font-size: 1rem;
  background-color: #424a9f;
  color: rgb(255, 255, 255);
  /* font-weight: bold; */
  outline: none;
  border: none;
  border-radius: 5px;
  transition: box-shadow 200ms ease-in-out;
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 10px;
  gap: 10px;
  cursor: pointer;
  text-decoration: none;
}

.App .content .buttons button:disabled,
.App .content .buttons .button2:disabled {
  background-color: #b8b8b8;
  color: #4d4d4d;
  cursor: not-allowed;
}

.App
  .content
  .buttons
  button:disabled:hover
  .App
  .content
  .buttons
  .button2:disabled:hover {
  box-shadow: none;
}

.App .content .buttons button:hover {
  box-shadow: 2px 2px 10px rgba(40, 97, 184, 0.671);
}

.App form {
  width: 40%;
  padding-left: 100px;
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
}

.App form p {
  font-size: 1rem;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.App form label {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.App form label input {
  padding: 10px;
  font-size: 1rem;
  width: 100%;
  outline: none;
  border: 1px solid gray;
  transition: box-shadow 200ms ease-in-out;
  border-radius: 5px;
}

.App form label input:focus {
  box-shadow: 2px 2px 10px rgba(77, 148, 255, 0.555);
}

.App form label input[type="checkbox"] {
  width: 30px;
  height: 30px;
}

.App form label input[type="radio"] {
  width: 20px;
  height: 20px;
}

@media screen and (max-width: 610px) {
  .App .header img {
    width: 120px;
    padding: none;
    margin: 0 auto;
  }

  .App .content {
    width: 100%;
  }

  .App .content .buttons {
    position: relative;
    top: 0;
    right: 0;
    display: flex;
    grid-gap: 20px;
    gap: 20px;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
    /* margin: 0 auto; */
  }
  .App .content .buttons button,
  .App .content .buttons .button2 {
    padding: 12px;
    font-size: 1rem;
  }

  .App form {
    width: 80%;
    padding: 0;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
  }

  .App form p {
    font-size: 1rem;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }

  .App form label {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .App form label input {
    width: 100%;
    padding: 10px;
    padding-right: 0;
    font-size: 1rem;
    outline: none;
    border: 1px solid gray;
    transition: box-shadow 200ms ease-in-out;
    border-radius: 5px;
  }

  .App form label input:focus {
    box-shadow: 2px 2px 10px rgba(77, 148, 255, 0.555);
  }

  .App form label input[type="checkbox"] {
    width: 30px;
    height: 30px;
  }

  .App form label input[type="radio"] {
    width: 20px;
    height: 20px;
  }
}

